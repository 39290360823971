import { VisitorConsentCollectedEvent } from '@integrabeauty/shopify-browser-types';
import { hasMarketingConsent } from './shopify-consent.js';

function appendScriptToHead() {
  let script = <HTMLScriptElement>document.getElementById('applovin-pixel');
  if (script) {
    return;
  }

  script = document.createElement('script');
  script.id = 'applovin-pixel';
  script.src = 'https://c.albss.com/p/l/loader.iife.js';
  script.async = true;

  const firstScript = document.querySelector('script');
  firstScript.parentNode.appendChild(script);
}

function onVisitorConsentCollected(event: VisitorConsentCollectedEvent) {
  if (event.detail.marketingAllowed) {
    appendScriptToHead();
  }
}

if (hasMarketingConsent()) {
  appendScriptToHead();
} else {
  document.addEventListener('visitorConsentCollected', onVisitorConsentCollected);
}
